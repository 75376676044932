<template>
  <div class="rightTable">
    <table>
      <thead>
        <td class="w64 blnone">模块</td>
        <td class="w64">功能</td>
        <td class="w108">说明</td>
        <td class="w64">基础功能</td>
        <td class="w64">定制功能</td>
      </thead>
      <tbody>
        <tr>
          <td rowspan="2">管理驾驶舱</td>
          <td>综合驾驶舱</td>
          <td></td>
          <td>√</td>
          <td></td>
        </tr>
        <tr>
          <td>综合驾驶舱</td>
          <td></td>
          <td></td>
          <td>√</td>
        </tr>

        <tr>
          <td rowspan="5">实时监控</td>
          <td>地图查看(2D)</td>
          <td></td>
          <td>√</td>
          <td></td>
        </tr>
        <tr>
          <td>地图查看(3D)</td>
          <td>三位城市底座</td>
          <td></td>
          <td>√</td>
        </tr>
        <tr>
          <td>列表查看</td>
          <td></td>
          <td>√</td>
          <td></td>
        </tr>
        <tr>
          <td rowspan="2">厂站工艺监控</td>
          <td>2D组态工艺</td>
          <td></td>
          <td>√</td>
        </tr>
        <tr>
          <!-- <td></td> -->
          <td>数字孪生厂站</td>
          <td></td>
          <td>√</td>
        </tr>

        <tr>
          <td rowspan="3">非实时监控</td>
          <td>地图查看(2D)</td>
          <td></td>
          <td>√</td>
          <td></td>
        </tr>
        <tr>
          <td>地图查看(3D)</td>
          <td>三维城市底座</td>
          <td></td>
          <td>√</td>
        </tr>
        <tr>
          <td>非实时站点管理</td>
          <td></td>
          <td>√</td>
          <td></td>
        </tr>

        <tr>
          <td rowspan="4">综合报表</td>
          <td>日报</td>
          <td></td>
          <td>√</td>
          <td></td>
        </tr>
        <tr>
          <td>月报</td>
          <td></td>
          <td>√</td>
          <td></td>
        </tr>
        <tr>
          <td>年报</td>
          <td></td>
          <td>√</td>
          <td></td>
        </tr>
        <tr>
          <td>定制综合报表</td>
          <td></td>
          <td></td>
          <td>√</td>
        </tr>

        <tr>
          <td rowspan="4">运行日报</td>
          <td>净水厂运行日报</td>
          <td></td>
          <td>√</td>
          <td></td>
        </tr>
        <tr>
          <td>污水厂运行日报</td>
          <td></td>
          <td>√</td>
          <td></td>
        </tr>
        <tr>
          <td>管网运行压力日报</td>
          <td></td>
          <td>√</td>
          <td></td>
        </tr>
        <tr>
          <td>定制运行日报</td>
          <td></td>
          <td></td>
          <td>√</td>
        </tr>

        <tr>
          <td>实时监测报警</td>
          <td>报警列表</td>
          <td></td>
          <td>√</td>
          <td></td>
        </tr>

        <tr>
          <td>个人中心</td>
          <td></td>
          <td></td>
          <td>√</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.rightTable {
  table {
    width: 410px;
    font-size: 12px;
    border-collapse: collapse;
    border-color: #3f93e8;
    text-align: center;
    font-weight: bold;
    thead {
      background-color: #3f93e8;
      color: #fff;
    }
    td {
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      padding: 0 6px;
      height: 25px;
      line-height: 25px;
    }

    .blnone {
      border-left: none;
    }
    tbody {
      background-color: #ebecf4;
    }
  }
}
</style>
